<template>
  <div>
    <div class="p-3 bg-white">
      <div>
        <InputSelect
          title="Privilege Branch"
          class="w-25"
          v-model="form.stock_branch_id"
          :options="branchList"
          textField="name"
          valueField="id"
          :isValidate="v.stock_branch_id.$error"
          isRequired
          :v="v.stock_branch_id"
          :disabled="form.is_redeem == 1"
        >
          <template v-slot:option-first>
            <b-form-select-option value="" disabled
              >-- Select Privilege Branch --</b-form-select-option
            >
          </template>
        </InputSelect>
        <b-row class="mb-2" v-if="form.mission_redeem_type == 2">
          <b-col cols="12" lg="8" xl="6">
            <b-row class="align-items-center row-gap-lg">
              <template v-for="(items, index) of stamp">
                <template v-if="index == 0">
                  <b-col cols="2" :key="'stamp-no-title-' + index">
                    Stamp No.
                  </b-col>
                  <b-col
                    :key="'stamp-title-' + index"
                    cols="10"
                    class="d-flex align-items-center"
                    >Condition (Spending per Transaction)
                    <b-form-checkbox
                      v-model="form.is_show_only_have_stamp"
                      size="sm"
                      :value="1"
                      :unchecked-value="0"
                      class="ml-2"
                    >
                      Show Only Have Stamps
                    </b-form-checkbox>
                  </b-col>
                </template>
                <b-col cols="2" :key="'stamp-no-' + index">
                  {{ index + 1 }}
                </b-col>
                <b-col :key="'stamp-input-' + index" cols="8">
                  <InputText
                    textFloat=""
                    v-model="items.redeem_target_id"
                    placeholder="0"
                    type="number"
                    :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 5);'"
                    class="mb-0"
                    :disabled="items.disabled || (userRedeem && items.id != 0)"
                    :v="$v.stamp.$each.$iter[index].redeem_target_id"
                    :isValidate="
                      $v.stamp.$each.$iter[index].redeem_target_id.$error
                    "
                  />
                  <div
                    class="input-description"
                    :style="
                      $v.stamp.$each.$iter[index].redeem_target_id.$error
                        ? 'top:32%'
                        : ''
                    "
                  >
                    {{
                      form.mission_redeem_condition == 1 ? "Quantity" : "Baht"
                    }}
                  </div>
                </b-col>

                <b-col cols="1" :key="'stamp-edit-' + index">
                  <b-button
                    v-if="!userRedeem || items.id == 0"
                    variant="custom-link"
                    class="px-0"
                    @click="items.disabled = false"
                  >
                    Edit
                  </b-button>
                </b-col>
                <b-col cols="1" :key="'stamp-trash-' + index">
                  <b-button
                    v-if="(!userRedeem || items.id == 0) && stamp.length > 1"
                    variant="icon"
                    class="px-0"
                    @click="removeStamp(index)"
                  >
                    <b-icon icon="trash-fill"></b-icon>
                  </b-button>
                </b-col>
              </template>
              <b-col cols="2">{{ stamp.length + 1 }}</b-col>
              <b-col cols="10">
                <b-button variant="custom-link" class="px-0" @click="addStamp">
                  Add Stamp
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <div class="title-tabs">
          Reward Detail <span class="text-error">*</span>
        </div>
        <div class="pt-3 pb-3">
          <div class="text-black">
            <div class="content-between">
              <div>
                <b-button
                  class="btn-select-branch w-100 w-sm-unset text-nowrap"
                  @click.prevent="openProductModal"
                  variant="custom-outline-primary"
                >
                  Select Item
                  <font-awesome-icon icon="chevron-right" class="ft-14 ml-2" />
                </b-button>
                <span class="ml-2">
                  (Selected {{ mission_items.length }} Items)
                </span>
              </div>
              <div>
                <b-button
                  class="w-sm-unset mr-2 text-nowrap"
                  @click.prevent="addReward(4)"
                  variant="custom-outline-primary"
                >
                  <font-awesome-icon icon="plus-square" class="ft-14 mr-2" />
                  Add Point Reward
                </b-button>
              </div>
            </div>

            <b-table
              :fields="fields"
              :items="mission_items"
              :busy.sync="isBusy"
              :perPage="filter.take"
              :currentPage="filter.page"
              @context-changed="tableChange"
              show-empty
              striped
              class="mt-3"
              empty-text="No matching records found"
              responsive
            >
              <template #cell(id)="row">
                <b-button
                  size="sm"
                  @click="row.toggleDetails"
                  class="mr-2"
                  variant="custom-link"
                >
                  <font-awesome-icon
                    icon="chevron-down"
                    v-if="!row.detailsShowing"
                  />

                  <font-awesome-icon icon="chevron-up" v-else />
                </b-button>
              </template>
              <template v-slot:cell(imageUrl)="data">
                <div class="position-relative picture-text pic-table">
                  <div
                    v-if="data.item.imageUrl"
                    class="square-box b-contain image"
                    v-bind:style="{
                      'background-image': 'url(' + data.item.imageUrl + ')',
                    }"
                  ></div>
                  <div
                    v-else-if="data.item.mission_detail_type_id == 3"
                    class="square-box b-contain image point-image"
                  ></div>

                  <div
                    v-else-if="data.item.image_url"
                    class="square-box b-contain image"
                    v-bind:style="{
                      'background-image': 'url(' + data.item.image_url + ')',
                    }"
                  ></div>
                  <div v-else>
                    <div
                      class="square-box b-contain image"
                      v-bind:style="{
                        'background-image': 'url(' + default_image + ')',
                      }"
                    ></div>
                  </div>
                </div>
              </template>

              <template v-slot:cell(when_reach)="data">
                <div class="position-relative">
                  <InputText
                    textFloat=""
                    v-model="data.item.when_reach"
                    placeholder="0"
                    type="number"
                    class="mb-0"
                    @input="
                      (val, e) => handleWhenReach(e, val, data.item.sort_order)
                    "
                    :disabled="
                      userRedeem && (data.item.active == 0 || data.item.id != 0)
                    "
                    :v="
                      $v.mission_items.$each.$iter[data.item.sort_order]
                        .when_reach
                    "
                    :isValidate="
                      $v.mission_items.$each.$iter[data.item.sort_order]
                        .when_reach.$error
                    "
                  />

                  <div
                    class="input-description"
                    :style="
                      $v.mission_items.$each.$iter[data.item.sort_order]
                        .when_reach.$error
                        ? 'top:32%'
                        : ''
                    "
                  >
                    {{ textType }}
                  </div>
                </div>
              </template>

              <template v-slot:cell(status)="data">
                <b-form-checkbox
                  switch
                  v-model="data.item.status"
                  :name="'' + data.item.id"
                  class="radio-active"
                  size="sm"
                  :value="1"
                  :unchecked-value="0"
                  @input="
                    handleActive(
                      data.item.reference_id != 0
                        ? getIndexByRef(data.item.reference_id)
                        : getIndexById(data.item.id),
                      $event
                    )
                  "
                >
                </b-form-checkbox>
              </template>
              <template v-slot:cell(name)="data">
                <div class="d-flex align-items-center">
                  <p class="m-0" v-if="data.item.name">
                    <b> {{ data.item.name }}</b>
                  </p>
                  <p class="m-0" v-else>
                    <b v-if="data.item.mission_detail_type_id == 3">
                      Point Reward
                    </b>
                  </p>

                  <small
                    class="ml-2"
                    v-if="data.item.mission_detail_type_id == 3"
                  >
                    ({{ data.item.redeem_target_id | numeral("0,0") }} Point)
                  </small>

                  <font-awesome-icon
                    v-if="
                      data.item.mission_detail_type_id == 3 &&
                      (data.item.active == 0 || data.item.id == 0)
                    "
                    icon="pencil-alt"
                    title="Edit Point"
                    class="ml-2 cursor-pointer text-warning"
                    @click="addPoint(data.item, getIndexById(data.item.id))"
                  />
                </div>
              </template>
              <template v-slot:cell(action)="data">
                <b-button
                  variant="icon"
                  class="px-0"
                  :disabled="
                    data.item.active == 0 || (userRedeem && data.item.id != 0)
                  "
                  @click="removeItems(getIndexCondition(data.item))"
                >
                  <b-icon icon="trash-fill"></b-icon>
                </b-button>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
              <template #row-details="row">
                <b-card>
                  <b-row class="align-items-center justify-content-space-aroud">
                    <b-col cols="12" sm="4" class="text-left">
                      <div class="main-label">
                        Condition For Receiving The Goods
                        <span class="text-error">*</span>
                      </div>

                      <b-form-group v-slot="{ ariaDescribedby }">
                        <b-form-radio-group
                          :id="
                            'receiving-' +
                            row.item.reference_id +
                            '-' +
                            getIndexByRef(row.item.reference_id) +
                            row.index
                          "
                          v-model="row.item.pick_up_type"
                          :value="row.item.pick_up_type"
                          :aria-describedby="ariaDescribedby"
                          :name="
                            'receiving-' +
                            row.item.reference_id +
                            '-' +
                            getIndexByRef(row.item.reference_id) +
                            row.index +
                            '-slot'
                          "
                          :disabled="
                            row.item.active == 0 ||
                            (userRedeem && row.item.id != 0)
                          "
                        >
                          <b-form-radio
                            :value="3"
                            v-show="row.item.mission_detail_type_id != 1"
                            >None
                          </b-form-radio>
                          <b-form-radio
                            :value="1"
                            v-show="row.item.mission_detail_type_id == 1"
                            >Pick up at branch
                          </b-form-radio>
                          <b-form-radio
                            :value="2"
                            v-show="row.item.mission_detail_type_id == 1"
                            >Pick up at home
                          </b-form-radio>
                        </b-form-radio-group>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" sm="4" class="text-left">
                      <div class="main-label">
                        Availiability <span class="text-error">*</span>
                      </div>

                      <b-form-group v-slot="{ ariaDescribedby }">
                        <b-form-radio-group
                          v-model="row.item.mission_availiablity_type"
                          :aria-describedby="ariaDescribedby"
                          :id="
                            'availiability-' +
                            row.item.reference_id +
                            '-' +
                            getIndexByRef(row.item.reference_id) +
                            row.index
                          "
                          :name="
                            'availiability-' +
                            row.item.reference_id +
                            '-' +
                            getIndexByRef(row.item.reference_id) +
                            row.index +
                            '-slot'
                          "
                          :disabled="
                            row.item.active == 0 ||
                            (userRedeem && row.item.id != 0)
                          "
                        >
                          <b-form-radio :value="1">Ready</b-form-radio>
                          <b-form-radio
                            :value="2"
                            v-show="row.item.mission_detail_type_id == 1"
                          >
                            Prepare
                          </b-form-radio>
                        </b-form-radio-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </template>
            </b-table>
            <Pagination
              @handleChangeTake="handleChangeTake"
              :pageOptions="pageOptions"
              :filter="filter"
              :rows="rows"
              @pagination="pagination"
            />

            <div
              v-if="$v.mission_items.$error && mission_items.length == 0"
              class="text-error"
            >
              Please select at least 1 items.
            </div>
            <!-- <div v-if="pleaseSelectProduct"></div> -->

            <hr />
          </div>
        </div>
      </div>
    </div>
    <ProductModal
      ref="ProductListModal"
      :form="mission_items"
      :freezeObj="allCurrentItems"
      @submitProduct="submitProduct"
      :disabledItems="isActive"
    />
    <b-modal
      v-model="pointModal"
      @hidden="clearPoint()"
      title="Points Reward"
      hide-header-close
      hide-footer
      centered
    >
      <InputText
        textFloat="Point Reward"
        placeholder="Point Reward"
        type="number"
        v-model="pointDetail.point"
        name="point_reward"
        isRequired
        :v="$v.pointDetail.point"
        :isValidate="$v.pointDetail.point.$error"
      />
      <div class="content-between">
        <b-button variant="custom-outline-primary" @click="pointModal = false"
          >Cancel</b-button
        >
        <b-button variant="custom-primary" @click="handleAddPoint()"
          >Confirm</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import ProductModal from "./Modal";
import { required, requiredIf } from "vuelidate/lib/validators";
import minValue from "vuelidate/lib/validators/minValue";

export default {
  name: "missionDetail",
  components: { ProductModal },
  validations() {
    return {
      mission_items: {
        required: requiredIf(function (item) {
          return item.$route.params.id == 0;
        }),
        $each: {
          when_reach: {
            required,
            minValue: minValue(1),
          },
        },
      },
      stamp: {
        $each: {
          redeem_target_id: {
            required,
            minValue: minValue(1),
          },
        },
      },
      pointDetail: {
        point: {
          required,
        },
      },
    };
  },
  data() {
    return {
      mission_items: [],
      branchList: [],
      delete_items: [],
      point_reward: 0,
      pointModal: false,
      data: [],
      fields: [
        { key: "id", label: "", class: "w-1" },
        { key: "imageUrl", label: "Image", class: "w-1" },
        { key: "name", label: "Name / Description", class: "w-4 text-left" },
        { key: "when_reach", label: "When Customer Reach", class: "w-2" },
        // { key: "win_rate", label: "Win Rate" },
        { key: "status", label: "Active" },
        { key: "action", label: "" },
      ],
      isBusy: false,
      rows: 0,
      filter: {
        page: 1,
        take: 5,
      },
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      freezeObj: [],
      pointDetail: { point: 0, id: 0, index: 0 },
      noRewardItems: false,
      allCurrentItems: [],
      isActive: false,
      stamp: [],
    };
  },
  props: {
    mission_detail: {
      required: false,
    },
    form: {
      required: false,
    },
    v: {
      required: false,
    },
    userRedeem: {
      required: false,
    },
  },
  computed: {
    textType() {
      if (this.form.mission_redeem_type == 1) return "Bath";
      else if (this.form.mission_redeem_type == 2) return "Stamps";
      else return "Point";
    },
  },
  created() {
    this.getBranchList();
    if (this.$route.params.id != 0) {
      this.freezeObj = JSON.parse(
        JSON.stringify(
          this.mission_detail
            .filter((el) => el.mission_detail_type_id != 4)
            .map((el, index) => el.id)
        )
      );
      this.mission_items = JSON.parse(
        JSON.stringify(
          this.mission_detail
            .filter((el) => el.mission_detail_type_id != 4)
            .map((el, index) => {
              return {
                ...el,
                // name: el.item_name,
                // imageUrl: el.item_img,
                sort_order: index,
                _showDetails: true,
              };
            })
        )
      );
      let stamp = this.mission_detail.filter(
        (el) => el.mission_detail_type_id == 4
      );
      if (stamp.length > 0) {
        this.stamp = [
          ...stamp.map((el) => {
            return { ...el, disabled: true };
          }),
        ];
      }
      this.rows = this.mission_items.length;
      // this.getItems();
    }
    if (this.form.mission_redeem_type == 2) {
      if (this.stamp.length == 0) this.addStamp();
    } else {
      this.stmap = [];
    }
  },
  watch: {
    "form.mission_redeem_type"(val) {
      if (val == 2) {
        if (this.stamp.length == 0) this.addStamp();
      } else {
        this.stmap = [];
      }
    },
  },
  methods: {
    async getBranchList() {
      const result = await this.$store.dispatch("getAllBranchList");
      this.branchList = result;
    },
    addPoint(items, index) {
      this.pointDetail.point = items.redeem_target_id;

      this.pointDetail.index = items.sort_order;

      this.pointModal = true;
    },
    handleAddPoint() {
      this.$v.pointDetail.$touch();

      if (this.$v.pointDetail.$error) return;
      if (this.pointDetail.index) {
        this.mission_items[this.pointDetail.index].redeem_target_id =
          this.pointDetail.point;
      } else {
        this.mission_items[this.pointDetail.index].redeem_target_id =
          this.pointDetail.point;
      }
      this.pointModal = false;
    },
    clearPoint() {
      this.pointDetail = { point: 0, id: 0, index: 0 };
    },

    addReward(type) {
      this.mission_items.push({
        id: 0,
        mission_detail_type_id: 3,
        redeem_target_id: 0,
        point: 0,
        when_reach: 1,
        pick_up_type: 3,
        mission_availiablity_type: 1,
        status: 1,
        item_img: "",
        item_name: "Point Reward",
        name: "Point Reward",
        imageUrl: "",
        _showDetails: true,
        sort_order: this.mission_items.length,
      });
      this.rows = this.mission_items.length;
      this.$nextTick(() => {
        this.filter.page = Math.ceil(
          this.mission_items.length / this.filter.take
        );
      });
    },

    async submitProduct(product) {
      this.tableChange();
      let temp = [
        ...product[0].map((el) => {
          return {
            ...el,
            id: this.freezeObj.includes(el.id) ? el.id : 0,
            mission_detail_type_id: 3, // product 1, coupon 2, point 3
            redeem_target_id: el.redeem_target_id, // value
            when_reach: el.when_reach || 1,
            pick_up_type: 3,
            mission_availiablity_type: el.mission_availiablity_type || 1,
            status: el.status == null || el.status == "" ? 1 : el.status,
            _showDetails: true,
          };
        }),
        ...product[1].map((el) => {
          return {
            ...el,
            id: this.freezeObj.includes(el.id) ? el.id : 0,
            mission_detail_type_id: 1, // product 1, coupon 2, point 3
            redeem_target_id: el.redeem_target_id, // value
            when_reach: el.when_reach || 1,
            pick_up_type: el.pick_up_type || 1,
            mission_availiablity_type: el.mission_availiablity_type || 1,
            status: el.status == null || el.status == "" ? 1 : el.status,
            _showDetails: true,
          };
        }),
        ...product[3].map((el) => {
          return {
            ...el,
            id: this.freezeObj.includes(el.id) ? el.id : 0,
            mission_detail_type_id: 2, // product 1, coupon 2, point 3
            redeem_target_id: el.redeem_target_id, // value
            when_reach: el.when_reach || 1,
            pick_up_type: el.pick_up_type || 3,
            mission_availiablity_type: el.mission_availiablity_type || 1,
            status: el.status == null || el.status == "" ? 1 : el.status,
            _showDetails: true,
          };
        }),
        ...product[5].map((el) => {
          return {
            ...el,
            id: this.freezeObj.includes(el.id) ? el.id : 0,
            mission_detail_type_id: 5, // product 1, coupon 2, point 3
            redeem_target_id: el.redeem_target_id, // value
            when_reach: el.when_reach || 1,
            pick_up_type: el.pick_up_type || 3,
            mission_availiablity_type: el.mission_availiablity_type || 1,
            status: el.status == null || el.status == "" ? 1 : el.status,
            _showDetails: true,
          };
        }),
      ];

      temp = temp.map((el, index) => {
        return { ...el, sort_order: el.sort_order || index };
      });

      this.noRewardItems = false;

      this.mission_items = temp.sort((a, b) => a.sort_order - b.sort_order);
      this.rows = temp.length;
      await this.$nextTick();
      this.$nextTick(() => {
        this.filter.page = Math.ceil(
          this.mission_items.length / this.filter.take
        );
      });
    },
    async openProductModal(type) {
      this.$refs.ProductListModal.show(1, this.mission_items);
    },

    removeItems(index) {
      this.mission_items.splice(index, 1);
      this.mission_items = this.mission_items.map((el, index) => {
        return { ...el, sort_order: index };
      });
      this.rows = this.mission_items.length;
    },
    handleWinrateInput(val, e, index) {
      var allWinrate = 0;
      var n = 0;
      for (const items of this.mission_items) {
        if (items.active == 1 && n != index) {
          allWinrate += Number(items.win_rate);
        }
        n++;
      }

      if ((Number(val) != 0 || val != "") && allWinrate + Number(val) > 100) {
        this.$nextTick(() => {
          e.target.value = 100 - allWinrate < 0 ? 0 : 100 - allWinrate;
          this.mission_items[index].win_rate =
            100 - allWinrate < 0 ? 0 : 100 - allWinrate;
          return e.preventDefault();
        });
      }
    },
    handleActive(index, val) {
      this.$nextTick(() => {
        if (val == 0) this.mission_items[index].win_rate = 0;
      });
    },

    emitActive(rows) {
      if (rows > 0) this.isActive = true;
    },
    handleDisabledItems(id) {
      if (id == 0) return false;
      let find = this.mission_items.find((el) => el.reference_id == id);
      if (find) {
        return this.isActive ? true : false;
      }
      return false;
    },
    getIndexById(id) {
      return this.mission_items.findIndex((el) => el.id == id);
    },
    getIndexByRef(id) {
      return this.mission_items.findIndex((el) => el.reference_id == id);
    },
    getIndexBySort(sort) {
      return this.mission_items.findIndex((el) => el.sort_order == sort);
    },
    getIndexCondition(item) {
      if (item.reference_id) return this.getIndexByRef(item.reference_id);
      else if (item.id == 0) return this.getIndexBySort(item.sort_order);
      return this.getIndexById(item.id);
    },
    tableChange(e, a) {
      this.isBusy = true;
      setTimeout(() => {
        this.isBusy = false;
      }, 1000);
    },
    addStamp() {
      this.stamp.push({
        id: 0,
        mission_detail_type_id: 4, // product 1, coupon 2, point 3
        redeem_target_id: 1, // value
        when_reach: 1,
        pick_up_type: 3,
        mission_availiablity_type: 1,
        status: 1,
        disabled: true,
      });
    },
    submit() {
      this.$v.mission_items.$touch();
      this.$v.stamp.$touch();

      if (this.$v.mission_items.$error || this.$v.stamp.$error) return false;
      let temp = [...this.mission_items, ...this.stamp];
      return temp;
    },
    removeStamp(index) {
      this.stamp.splice(index, 1);
    },
    handleChangeTake() {},
    pagination() {},
    handleWhenReach(e, val, index) {
      if (this.form.mission_redeem_type == 2) {
        if (val > this.stamp.length) {
          this.$nextTick(() => {
            this.mission_items[index].when_reach = this.stamp.length;
            e.target.value = this.stamp.length;
            return e.preventDefault();
          });
        }
      }
      if (
        this.form.mission_redeem_type == 1 ||
        this.form.mission_redeem_type == 3
      ) {
        if (val > this.form.mission_redeem_goal) {
          this.$nextTick(() => {
            this.mission_items[index].when_reach =
              this.form.mission_redeem_goal;
            e.target.value = this.form.mission_redeem_goal;
            return e.preventDefault();
          });
        }
      }
    },
  },
};
</script>

<style>
.point-image {
  background-image: url(../../../../assets/img/point.png);
}
.justify-content-space-aroud {
  justify-content: space-around;
  /* }

.no-reward-image {
  background-image: url(../../../assets/img/Fail.png); */
}
.input-description {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 6.5%;
  color: rgb(189, 189, 189);
}
</style>
